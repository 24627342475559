<script lang="ts">
  import type { Image } from '$lib/contentful/models/image';

  import type { Background } from '$lib/contentful/models/background';

  import type { SvelteHTMLElements } from 'svelte/elements';

  type T = $$Generic<keyof SvelteHTMLElements>;

  type $$Props = SvelteHTMLElements[T] & {
    as: T;
    class?: string;
    id?: string;
    layout: 'rows' | 'columns';
    reverse?: boolean;
    background?: Background;
    bordered?: boolean;
    backgroundImage?: Image;
  };

  let className = '';
  export { className as class };
  export let as: T;
  export let id: string | undefined = undefined;
  export let layout: 'rows' | 'columns';
  export let reverse: boolean = false;
  export let background: Background = 'none';
  export let bordered = false;
  export let backgroundImage: Image | undefined = undefined;

  const layoutClassNames = {
    columns:
      'grid grid-flow-col-dense sm:grid-cols-page sm:gap-x-4 max-sm:grid-cols-1 max-sm:px-5 max-sm:gap-y-4',
    rows: 'grid grid-flow-row auto-rows-max',
  };

  const bgClassNames: Record<Background, string> = {
    black: 'bg-space-black',
    'green-gradient': 'bg-gradient-green',
    'purple-ultraviolet-gradient-stars-grid':
      'bg-gradient-ultraviolet-stars-grid',
    'purple-ultraviolet-gradient': 'bg-gradient-ultraviolet',
    'pink-gradient': 'bg-gradient-pink',
    'black-ultraviolet-gradient-grid': 'bg-black-ultraviolet-gradient-grid',
    'black-blue-gradient-stars': 'bg-black-blue-gradient-stars',
    'black-blue-gradient-stars-grid': 'bg-black-blue-gradient-stars-grid',
    'customer-hype-rainbow': 'bg-customer-hype-rainbow',
    'windows-95': 'bg-windows-95',
    tardigrade: 'bg-tardigrade',
    stars: 'bg-stars',
    grid: 'bg-grid',
    none: 'bg-none bg-transparent',
    'deep-blue': 'bg-deep-blue',
    cloud: 'bg-cloud-platform',
    oss: 'bg-oss-platform',
    moonscape: 'bg-moonscape',
    abstract: 'bg-abstract',
    mist: 'bg-gradient-mist',
  };
</script>

<svelte:element
  this={as}
  {id}
  class="{layoutClassNames[layout]} {bgClassNames[
    background
  ]} {className} {layout} temporal-grid"
  class:reverse
  class:bordered
  style={backgroundImage
    ? `background-image: url('${backgroundImage.url}'); background-repeat: no-repeat; background-size: cover;`
    : ''}
>
  <slot />
</svelte:element>

<style lang="postcss">
  .temporal-grid.rows > :not(.no-padding) {
    @apply max-sm:py-12 sm:py-24;
  }

  .bordered {
    @apply border-[24px] border-space-black;
  }

  .bg-gradient-ultraviolet-stars-grid {
    background-image: url('/images/backgrounds/stars.png'),
      linear-gradient(45deg, rgb(68 76 231 / 75%), rgb(182 100 255 / 95%)),
      url('/images/backgrounds/grid-perspective.webp');

    @supports (background-image: url('/images/backgrounds/stars.avif')) {
      background-image: url('/images/backgrounds/stars.avif'),
        linear-gradient(45deg, rgb(68 76 231 / 75%), rgb(182 100 255 / 95%)),
        url('/images/backgrounds/grid-perspective.avif');
    }

    background-size: cover;
    background-repeat: no-repeat;
  }

  .bg-black-ultraviolet-gradient-grid {
    background-image: url('/images/backgrounds/ultraviolet-grid-opacity.png'),
      linear-gradient(185deg, #444ce7 -20%, #141414 40%);

    background-size: contain-y;
    background-repeat: repeat;
  }

  .bg-black-blue-gradient-stars {
    background-image: linear-gradient(0deg, #1e1f3e, #1e1f3e00),
      url('/images/backgrounds/stars.png');

    @supports (background-image: url('/images/backgrounds/stars.avif')) {
      background-image: linear-gradient(0deg, #1e1f3e, #1e1f3e00),
        url('/images/backgrounds/stars.avif');
    }

    background-size: contain;
    background-repeat: repeat;
  }

  .bg-customer-hype-rainbow {
    background: url('/images/backgrounds/noise.svg'),
      radial-gradient(
        circle farthest-corner at 115% 5%,
        rgba(255, 175, 0, 1) 20%,
        rgba(255, 255, 255, 0) 65%
      ),
      radial-gradient(
        circle farthest-corner at -30% -20%,
        rgba(34, 0, 255, 0.85) 20%,
        rgba(255, 255, 255, 0) 90%
      ),
      radial-gradient(
        circle at 50% 120%,
        rgba(255, 0, 223, 0.85) 40%,
        rgba(255, 255, 255, 0) 75%
      ),
      radial-gradient(
        circle farthest-corner at 10% 120%,
        rgba(0, 255, 191, 1) 20%,
        rgba(255, 255, 255, 0) 40%
      ),
      #141414;

    background-repeat: repeat;
  }

  .bg-stars {
    @apply bg-background;
    background-image: url('/images/backgrounds/stars.png');
    @supports (background-image: url('/images/backgrounds/stars.avif')) {
      background-image: url('/images/backgrounds/stars.avif');
    }
    background-size: 100% auto;
    background-repeat: repeat;
  }
  .bg-moonscape {
    @apply bg-background;
    background-image: url('/images/backgrounds/temporal-moonscape-no-clouds.svg'),
      url('/images/backgrounds/stars.png');
    @supports (background-image: url('/images/backgrounds/stars.avif')) {
      background-image: url('/images/backgrounds/temporal-moonscape-no-clouds.svg'),
        url('/images/backgrounds/stars.avif');
    }
    background-size: 100% auto;
    background-position: bottom, center;
    background-repeat: no-repeat, repeat;
  }

  .bg-grid {
    background-image: url('/images/backgrounds/grid.png');

    @supports (background-image: url('/images/backgrounds/grid.avif')) {
      background-image: url('/images/backgrounds/grid.avif');
    }

    background-size: contain;
  }

  .bg-black-blue-gradient-stars-grid {
    background-image: url('/images/backgrounds/stars.png'),
      linear-gradient(180deg, rgba(17, 24, 39, 0.9), rgb(15 15 15 / 90%)),
      url('/images/backgrounds/grid-perspective.webp');

    @supports (background-image: url('/images/backgrounds/stars.avif')) {
      background-image: url('/images/backgrounds/stars.avif'),
        linear-gradient(180deg, rgba(17, 24, 39, 0.9), rgb(15 15 15 / 90%)),
        url('/images/backgrounds/grid-perspective.avif');
    }

    background-size: cover;
    background-repeat: no-repeat;
  }

  .bg-tardigrade {
    background-image: url('/images/backgrounds/stars.png'),
      url('/images/asteroid-on-light.gif'),
      linear-gradient(180deg, #111827, rgb(15 15 15 / 90%)),
      url('/images/backgrounds/grid-perspective.webp');

    @supports (background-image: url('/images/backgrounds/stars.avif')) {
      background-image: url('/images/backgrounds/stars.avif'),
        url('/images/asteroid-on-light.gif'),
        linear-gradient(180deg, #111827, rgb(15 15 15 / 90%)),
        url('/images/backgrounds/grid-perspective.avif');
    }

    background-position: 0% 0%, 40vw 0%, 0% 0%, 0% 0%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .bg-windows-95 {
    background: url('/images/backgrounds/3d-morph-lines.svg'),
      url(/images/backgrounds/noise.svg),
      radial-gradient(
        circle farthest-corner at 70% 100%,
        rgba(255, 0, 223, 0.75) 0%,
        rgba(68, 76, 231, 0.1) 50%,
        rgba(0, 0, 0, 0) 100%
      ),
      radial-gradient(
        circle farthest-corner at 15% 100%,
        rgba(89, 253, 160, 0.65) 0%,
        rgba(68, 76, 231, 0.1) 50%,
        rgba(0, 0, 0, 0) 100%
      ),
      radial-gradient(
        circle farthest-corner at 0% 25%,
        rgba(89, 253, 160, 0.35) 0%,
        rgba(68, 76, 231, 0.1) 30%,
        rgba(0, 0, 0, 0) 100%
      ),
      radial-gradient(
        circle farthest-corner at 100% 0%,
        rgba(68, 76, 231, 1) 0%,
        rgba(0, 0, 0, 0.1) 100%
      ),
      #141414;

    background-position: right, left;
    background-size: contain, cover;
    background-repeat: no-repeat, repeat;
  }

  .bg-cloud-platform {
    background-image: url('/images/backgrounds/cloud-resource-bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @supports (
      background-image: url('/images/backgrounds/cloud-resource-bg.avif')
    ) {
      background-image: url('/images/backgrounds/cloud-resource-bg.avif');
    }
  }

  .bg-oss-platform {
    background-image: url('/images/backgrounds/self-hosted-resource-bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @supports (
      background-image: url('/images/backgrounds/self-hosted-resource-bg.avif')
    ) {
      background-image: url('/images/backgrounds/self-hosted-resource-bg.avif');
    }
  }

  .bg-abstract {
    background-image: url('/images/backgrounds/abstract.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    @supports (background-image: url('/images/backgrounds/abstract.avif')) {
      background-image: url('/images/backgrounds/abstract.avif');
    }
  }

  .reverse {
    @apply -order-1;
  }
</style>
