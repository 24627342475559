<script lang="ts" context="module">
  export const GRID_SPAN_CLASS_NAMES = [
    '',
    'max-sm:col-span-1 sm:col-span-1',
    'max-sm:col-span-1 sm:col-span-2',
    'max-sm:col-span-1 sm:col-span-3',
    'max-sm:col-span-1 sm:col-span-4',
    'max-sm:col-span-1 sm:col-span-5',
    'max-sm:col-span-1 sm:col-span-6',
    'max-sm:col-span-1 sm:col-span-7',
    'max-sm:col-span-1 sm:col-span-8',
    'max-sm:col-span-1 sm:col-span-9',
    'max-sm:col-span-1 sm:col-span-10',
    'max-sm:col-span-1 sm:col-span-11',
    'max-sm:col-span-1 sm:col-span-12',
  ];

  export const GRID_OFFSET_CLASS_NAMES = [
    '',
    'max-sm:col-start-1 sm:col-start-1',
    'max-sm:col-start-1 sm:col-start-2',
    'max-sm:col-start-1 sm:col-start-3',
    'max-sm:col-start-1 sm:col-start-4',
    'max-sm:col-start-1 sm:col-start-5',
    'max-sm:col-start-1 sm:col-start-6',
    'max-sm:col-start-1 sm:col-start-7',
    'max-sm:col-start-1 sm:col-start-8',
    'max-sm:col-start-1 sm:col-start-9',
    'max-sm:col-start-1 sm:col-start-10',
    'max-sm:col-start-1 sm:col-start-11',
    'max-sm:col-start-1 sm:col-start-12',
  ];
</script>

<script lang="ts">
  import type { SvelteHTMLElements } from 'svelte/elements';

  type T = $$Generic<keyof SvelteHTMLElements>;
  type $$Props = SvelteHTMLElements[T] & {
    as: T;
    class?: string;
    span?: number;
    offset?: number;
  };

  let className = '';
  export { className as class };
  export let as: T;
  export let span = 10;
  export let offset = 3;
</script>

<svelte:element
  this={as}
  class="{GRID_SPAN_CLASS_NAMES[span]} {GRID_OFFSET_CLASS_NAMES[
    offset
  ]} {className}"
>
  <slot />
</svelte:element>
